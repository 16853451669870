import React from 'react';
import './App.sass';
import { Switch, Route, Redirect } from 'react-router-dom';
import OrderForm from './Components/OrderForm';

function App() {
  return (
    <main>
      <Switch>
        <Route path="/at/:userId/:dealershipId/:orderId/:form/:brand">
          <OrderForm />
        </Route>
        <Route path="/:form/:brand">
          <OrderForm />
        </Route>
        <Route path="/">
          <Redirect to="/cs/avalon/m" />
        </Route>
      </Switch>
    </main>
  );
}

export default App;
