import React from 'react';
import { useParams } from 'react-router-dom';

const Price = (props) => {
  const { form } = useParams();
  const price = form === "cs" ? (props.price/0.64).toFixed(0) : props.price;
  let priceStr = "";
  if (price !== "0.00" && price !== 0 && price !== "0") {
    priceStr = `$${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    return (
      <span>
        {props.noSeparator !== undefined ? null : <span className="text-secondary"> - </span>}
        <span className="text-primary">{priceStr}</span>
      </span>
    );
  } else {
    return null;
  }
}

export default Price;