import React, { useEffect, useReducer, useState } from 'react';
import { Switch, Route, useRouteMatch, useParams, Redirect } from 'react-router-dom';
import { Container } from 'reactstrap';
import _ from 'lodash';
import './OrderForm.sass';
import PrimarySelect from './Components/PrimarySelect';
import FormNav from './Components/FormNav';
import BoatGallery from './Components/BoatGallery';
import FormPage from './Components/FormPage';
import Review from './Components/Review';
import { initFormOptions,
  orderReducer,
  initialState,
  checkOptionRules,
  checkRuleGroups,
  calculatePrice } from './reducer';
import axios from 'axios';

export const OrderContext = React.createContext();

const OrderForm = (props) => {
  const { path, url } = useRouteMatch();
  const { form, brand, orderId } = useParams();
  const [order, dispatch] = useReducer(orderReducer, initialState);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  useEffect(() => {
    if (brand === 'avalon') {
      const script = document.createElement( 'script' );
      script.type = 'text/javascript';
      script.text =  `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-KPH894');`
      document.getElementsByTagName('head')[0].appendChild( script );

      const noscript = document.createElement( 'noscript' );
      noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KPH894"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
      document.getElementsByTagName('body')[0].appendChild( noscript );
    } else {
      const script = document.createElement( 'script' );
      script.type = 'text/javascript';
      script.text =  `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-N7FMJN');`
      document.getElementsByTagName('head')[0].appendChild( script );

      const noscript = document.createElement( 'noscript' );
      noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-N7FMJN"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
      document.getElementsByTagName('body')[0].appendChild( noscript );
    }
  }, [brand]);

  useEffect(() => {
    const fetchOptionsAndRules = async () => {
      const userType = form === "dl" ? "Dealers" : "Customer";

      axios(`${process.env.REACT_APP_API_URL}/v3/options/rules?user_type=${userType}`, {
        method: 'GET',
        mode: 'no-cors',
      }).then((response) => {
        const result = response.data;
        
        if (orderId !== undefined && orderId !== 'n') {
          // Fetch order
          axios(`${process.env.REACT_APP_API_URL}/v3/order?order_id=${orderId}`, {
            method: 'GET',
            mode: 'no-cors'
          }).then((response) => {
            const order = response.data.order;
            const initializedForm = initFormOptions(result.options, result.rules, result.rule_groups, result.rule_group_conditions, order);
            const pages = [
              {id: 1, name: 'Initial Boat Build', note: (response.data.order.page_1 !== null ? response.data.order.page_1 : "")},
              {id: 2, name: 'Packages', note: (response.data.order.page_2 !== null ? response.data.order.page_2 : "")},
              {id: 3, name: 'Performance/Pontoon', note: (response.data.order.page_3 !== null ? response.data.order.page_3 : "")},
              {id: 4, name: 'Deck Features', note: (response.data.order.page_4 !== null ? response.data.order.page_4 : "")},
              {id: 5, name: 'Stand Features', note: (response.data.order.page_5 !== null ? response.data.order.page_5 : "")},
              {id: 6, name: 'Wall Features', note: (response.data.order.page_6 !== null ? response.data.order.page_6 : "")},
              {id: 7, name: 'Furniture Features', note: (response.data.order.page_7 !== null ? response.data.order.page_7 : "")},
              {id: 8, name: 'Canvas/Top', note: (response.data.order.page_8 !== null ? response.data.order.page_8 : "")}
            ];
            dispatch({type: "INIT_FORM_WITH_PAGES", options: initializedForm.initializedOptions, rule_groups: initializedForm.initializedRuleGroups, price: initializedForm.price, customer_name: order.customer_name, customer_phone: order.customer_phone, customer_email: order.customer_email, customer_zipcode: order.customer_zipcode, customer_contact_preference: order.customer_contact_preference, order_source: order.order_source, order_status: order.order_status, id: order.id, pages});
            setLoading(false);
          }).catch((e) => {
            console.log(e);
          })
        } else {
          const initializedForm = initFormOptions(result.options, result.rules, result.rule_groups, result.rule_group_conditions);
          dispatch({type: "INIT_FORM", options: initializedForm.initializedOptions, rule_groups: initializedForm.initializedRuleGroups, customer_name: ""});
          setLoading(false);
        }
      }).catch((e) => {
        console.log(e);
      });
    }

    fetchOptionsAndRules();
  }, [form, brand, orderId]);

  const handleSelectOption = (i, o) => {
    console.log("CHANGING OPTION: ", i,o,order.rule_groups)
    let selected_option = null;
    let options = order.options;
    let rule_groups = order.rule_groups;

    if (i.type === "Checkbox") {
      i.checked = o;
      i.selected_option = o;
    }

    if (i.type === "Dropdown" && o !== "" && o !== null) {
      i.selected_option = o;
      selected_option = _.find(i.options, {'id': o});
    }
    if (i.id === "2") {
      // We're updating floorplan, reset all options
      let non_default_options =  _.filter(options, function(o) {
        if (o.disabled !== "1" && o.active === "1" && o.id !== "1" && o.id !== "2") {
          if (o.type === "Checkbox" && o.checked === true && (o.disabled_rule === null || o.disabled_rule === undefined)) {
            return o;
          }
          if (o.type === "Dropdown" && o.selected_option !== null) {
            // check dropdown options for default rule
            let has_default = false;
            _.each(o.options, function(ddo) {
              if (ddo.default_rule !== undefined) has_default = true;
            });

            if (has_default) return 0;
          }
        }
      })
      console.log("NON-DEFAULT SELECTED OPTIONS: ", non_default_options);

      _.each(non_default_options, function(ndo) {
        // if (ndo.type === "Checkbox") ndo.checked = false;
        if (ndo.type === "Dropdown") ndo.selected_option = null;
      });
    }
    
    if (i.rules !== undefined) options = checkOptionRules(i, selected_option, options);
    if (i.has_rule_group !== undefined && i.has_rule_group) rule_groups = checkRuleGroups(i, options, rule_groups);
    const price = calculatePrice(options);
    // Reset the selected option to be an id and not an object
    if (i.selected_option !== null) i.selected_option = i.selected_option.id;
    // console.log("NEW OPTIONS: ", options, rule_groups, price);
    // console.log("SELECTED OPTIONS: ", _.filter(options, function(o) {
    //   if (o.disabled !== "1" && o.active === "1") {
    //     if (o.type === "Checkbox" && o.checked === true) return o;
    //     if (o.type === "Dropdown" && o.selected_option !== null) return o;
    //   }
    // }));
    dispatch({type: "UPDATE_FORM", options, rule_groups, price});
  }

  const setCustomerName = (e) => {
    dispatch({type: "SET_CUSTOMER_NAME", customer_name: e.target.value});
  }

  const setNote = (e, s) => {
    dispatch({type: "SET_NOTE", note: e.target.value, step: parseInt(s, 10)});
  }

  return (
    <OrderContext.Provider value={{orderState: order, orderReducer: dispatch}}>
      <div id="order-form" className={form === "cs" ? 'with-notification' : ''}>
        {loading ? <h1>Loading</h1> :
          <Switch>
            <Route path={`${path}/m/:model/f/:floorplan/review`}>
              <FormNav toggle={toggle} modal={modal} order={order} dispatch={dispatch} handleSelectOption={handleSelectOption} />
              <Container className="form-container">
                <BoatGallery order={order} />
                <Review order={order} toggle={toggle} modal={modal} dispatch={dispatch} handleSelectOption={handleSelectOption} pages={order.pages} />
              </Container>
            </Route>
            <Route path={`${path}/m/:model/f/:floorplan/s/:step`}>
              <FormNav order={order} dispatch={dispatch} handleSelectOption={handleSelectOption} />
              <Container className="form-container">
                <BoatGallery order={order} />
                <FormPage
                  order={order}
                  dispatch={dispatch}
                  handleSelectOption={handleSelectOption}
                  pages={order.pages}
                  setCustomerName={setCustomerName}
                  setNote={setNote}
                />
              </Container>
            </Route>
            <Route path={`${path}/m/:model`}>
              <FormNav order={order} dispatch={dispatch} handleSelectOption={handleSelectOption} />
              <Container className="form-container">
                <PrimarySelect order={order} dispatch={dispatch} handleSelectOption={handleSelectOption} />
              </Container>
            </Route>
            <Route path={`${path}/m`}>
              <FormNav order={order} dispatch={dispatch} handleSelectOption={handleSelectOption} />
              <Container className="form-container">
                <PrimarySelect order={order} dispatch={dispatch} handleSelectOption={handleSelectOption} />
              </Container>
            </Route>
            <Route path={`${path}/`} exact>
              <Redirect to={`${url}/m`} />
            </Route>
          </Switch>
        }
      </div>
    </OrderContext.Provider>
  );
}

export default OrderForm;