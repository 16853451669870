import React, { useState, useContext, useEffect } from 'react';
import {
  Col,
  Row,
  Button
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useParams, useHistory } from 'react-router-dom';
import './FormNav.sass';
import _ from 'lodash';
import { OrderContext } from '../../index';

const FormNav = (props) => {
  const { step, form, brand, model, floorplan, userId, dealershipId, orderId} = useParams();
  const history = useHistory();
  const [navigatingBack, setNavigatingBack] = useState(false);
  const orderContext = useContext(OrderContext);
  const modelInput = orderContext.orderState.options !== undefined ? _.find(orderContext.orderState.options, {'id': '1'}) : null;
  const floorplanInput = orderContext.orderState.options !== undefined ? _.find(orderContext.orderState.options, {'id': '2'}) : null;
  const selected_model = model !== undefined && modelInput.selected_option !== null && modelInput.selected_option !== "" ? _.find(modelInput.options, {'id': model}) : null;
  const selected_floorplan = floorplan !== undefined && floorplanInput.selected_option !== null && floorplanInput.selected_option !== "" ? _.find(floorplanInput.options, {'id': floorplan}) : null;
  const price = (form === "cs" ? (orderContext.orderState.price/0.64).toFixed(0) : orderContext.orderState.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const internet_price = ((orderContext.orderState.price/0.64)*0.84).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const base_url = (userId !== undefined) ? `/at/${userId}/${dealershipId}/${orderId}/${form}/${brand}` : `/${form}/${brand}`;
  const review_base_url = (userId !== undefined) ? `/at/${userId}/${dealershipId}/${orderId}/${form}/${brand}/m/${model}/f/${floorplan}` : `/${form}/${brand}/m/${model}/f/${floorplan}`;
  const next_url = step !== undefined && parseInt(step, 10) < 8 ? `${base_url}/m/${model}/f/${floorplan}/s/${parseInt(step, 10)+1}` : `${base_url}/m/${model}/f/${floorplan}/review`;
  
  const requiredOptionsCheck = _.filter(orderContext.orderState.options, function(o) {
    const checkboxCheck = (o.type === "Checkbox" && o.checked === false);
    const dropdownCheck = (o.type === "Dropdown" && (o.selected_option === null || o.selected_option === ""));
    if (o.required === "1" && (checkboxCheck || dropdownCheck) && o.disabled !== "1" && o.id !== "80") return o;
  });

  useEffect(() => {
    if (model !== undefined && selected_model === null && !navigatingBack) props.handleSelectOption(modelInput, model)
    if (floorplan !== undefined && selected_floorplan === null && !navigatingBack) props.handleSelectOption(floorplanInput, floorplan)
  })

  const createBackUrl = () => {
    if (model !== undefined && floorplan !== undefined) {
      if (step === undefined) {
        return `${base_url}/m/${model}/f/${floorplan}/s/8`;
      } else {
        let parsed_step = parseInt(step, 10);
        return parsed_step === 1 ? `${base_url}/m/${model}/f` : `${base_url}/m/${model}/f/${floorplan}/s/${parsed_step-1}`;
      }
    } else if (model !== undefined && floorplan === undefined) {
      return `${base_url}/m`;
    } else { return `${base_url}/m` }
  }
  const back_url = createBackUrl();

  const getBrandedName = () => {
    return brand === "tahoe" && brand !== null ? selected_model.tahoe_name : selected_model.avalon_name;
  }

  const goBack = () => {
    setNavigatingBack(true);
    if (step === "1")  props.handleSelectOption(floorplanInput, "");
    if (back_url === `${base_url}/m`) props.handleSelectOption(modelInput, "");

    history.push(back_url);
  }

  const actionTitle = () => {
    if (userId === undefined) {
      return 'Get Quote';
    } else {
      if (orderId !== 'n') {
        return 'Update';
      } else {
        return 'Save';
      }
    }
  }

  const handleReviewClick = () => {
    if (requiredOptionsCheck.length === 0) {
      history.push(`${review_base_url}/review`)
    } else {
      if (orderContext.orderState.show_required === false) orderContext.orderReducer({type: "TOGGLE_SHOW_REQUIRED", show_required: true})
      history.push(`${review_base_url}/s/${requiredOptionsCheck[0].page}`)
    }
  }

  const handleSubmitClick = () => {
    if (requiredOptionsCheck.length === 0) {
      props.toggle();
    } else {
      if (orderContext.orderState.show_required === false) orderContext.orderReducer({type: "TOGGLE_SHOW_REQUIRED", show_required: true})
      history.push(`${review_base_url}/s/${requiredOptionsCheck[0].page}`)
    }
  }

  return (
    <div className="fixed-top">
      <Row className="order-form-nav border-0 pt-2 mx-0">
        <Col>
          <Button onClick={() => goBack()} className="btn btn-dark float-left" disabled={model === undefined}><FontAwesomeIcon icon={faChevronLeft} /> Back</Button>
        </Col>
        <Col>
        { (model !== undefined && floorplan !== undefined && step === undefined) ?
          <Button color={requiredOptionsCheck.length === 0 ? "primary" : "dark"} block onClick={() => handleSubmitClick()}>{actionTitle()}</Button> :
          <Button color={requiredOptionsCheck.length === 0 ? "primary" : "dark"} block onClick={() => handleReviewClick()} disabled={model === undefined || floorplan === undefined}><span className="d-none d-sm-inline">Review and </span>Get Quote</Button> 
        }
        </Col>
        <Col>
          <Button onClick={() => history.push(next_url)} className="btn btn-dark float-right" disabled={floorplan === undefined}>Next <FontAwesomeIcon icon={faChevronRight} /></Button>
        </Col>
      </Row>
      <Row className="order-form-nav border-secondary py-2 shadow-sm mx-0">
        <Col className="align-self-center border-right">{selected_model !== null && selected_model !== undefined ? getBrandedName() : 'Select Model'}</Col>
        <Col className="align-self-center border-right">{selected_floorplan !== null && selected_floorplan !== undefined && floorplan !== undefined ? selected_floorplan.avalon_name : 'Floorplan'}</Col>
        <Col className="align-self-center border-right">{price !== null && price !== 0 && selected_floorplan !== null && selected_floorplan !== "" && floorplan !== undefined ? `$${price} ${form === 'cs' ? 'MSRP' : ''}` : 'Price'}</Col> 
        {form === "cs" ? <Col className="align-self-center">{price !== null && price !== 0 && selected_floorplan !== null && selected_floorplan !== "" && floorplan !== undefined ? `$${internet_price} Internet Price` : 'Price'}</Col> : null}
      </Row>
    </div>
    
  );
}

export default FormNav;