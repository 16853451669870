import React, { useState, useContext } from 'react';
import { 
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Input,
  FormGroup,
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label
} from 'reactstrap';
import Checkbox from './Components/Checkbox';
import Dropdown from './Components/Dropdown';
import DropdownGrid from './Components/DropdownGrid';
import FormNavBottom from './Components/FormNavBottom';
import FormNav from './Components/FormNav';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { OrderContext } from '../../index';

const OrderFormPage = (props) => {
  let { step, form } = useParams();
  const orderContext = useContext(OrderContext);

  const page_note = props.order.pages[step-1].note;

  const sortOptionsByGroup = (pageInputs) => {
    let groupedInputs = _.groupBy(pageInputs, 'input_group');
    // This just converts the object to an array
    groupedInputs = _.sortBy(groupedInputs, 'input_order');
    // This is where the grouped inputs are actually ordered
    groupedInputs.map(function(gi, index) {
      return groupedInputs[index] = _.orderBy(gi, 'input_order');
    });
    console.log("GROUPED PAGE INPUTS: ", groupedInputs);
    return groupedInputs;
  }

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [modalProps, setModalProps] = useState({title: "", body_text: ""});
  // const [orderFormStyle, setOrderFormStyle] = useState({maxHeight: `${window.innerHeigh-275}px`});
  const inputs = sortOptionsByGroup(_.filter(props.order.options, {'page': step}));

  const openTooltip = (props) => {
    setModalProps(props);
    toggle();
  }
  
  return (
    <Container className="order-form-page">
      <Row>
        <Card className="shadow-sm w-100 mb-2">
          <CardBody>
            <Row>
              <Col md="4">
                <FormNav order={props.order} pages={props.pages} />
              </Col>
              <Col md="8" id="order-page-form">
                <Form className="pb-3">
                  <h3 className="text-dark">{_.find(props.pages, {id: parseInt(step, 10)}).name}</h3>
                  <Container className="p-0">
                    {inputs.map((groupedInputs, index) => {
                      // Filter hidden inputs
                      let filteredInputs = _.filter(groupedInputs, function(i) {
                        if (i.active === "1" && i.id !== "1" && i.id !== "2" && i.disabled !== "1") return i;
                      });
                      if (filteredInputs.length === 0) return null;
                      return (
                        <Row className="order-form-input-group py-2" key={index}>
                          {filteredInputs.map((i) => {
                            let formattedInput = null;
                            let toggleTooltip = i.tooltip !== null ? () => openTooltip({title: i.name, img_path: i.tooltip.img_path, body_text: i.tooltip.body_text}) : null;
                            
                            if (i.type === "Dropdown" && i.grid_layout === "0") {
                              formattedInput = (
                                <Col sm="12" md="6" key={i.id}>
                                  <Dropdown
                                    disabled={i.disabled === "1" || (form === 'dl' && i.user_type === "Customer")}
                                    toggleTooltip={toggleTooltip}
                                    show_required={orderContext.orderState.show_required}
                                    input={i}
                                    handleSelectOption={props.handleSelectOption}
                                  />
                                </Col>
                              )
                            } else if (i.type === "Dropdown" && i.grid_layout === "1") {
                              formattedInput = (
                                <Col sm="12" key={i.id}>
                                  <DropdownGrid
                                    disabled={i.disabled === "1"}
                                    toggleTooltip={toggleTooltip}
                                    base_img_url={props.order.base_img_url}
                                    show_required={orderContext.orderState.show_required}
                                    input={i}
                                    handleSelectOption={props.handleSelectOption}
                                  />
                                </Col>
                              )
                            } else if (i.type === "Checkbox") {
                              let img_url = i.img_path !== null ? props.order.base_img_url + i.img_path : null;
                              formattedInput = (
                                <Col sm="12" md="6" key={i.id}>
                                  <Checkbox disabled={i.disabled === "1"} toggleTooltip={toggleTooltip} input={i} img_url={img_url} handleSelectOption={props.handleSelectOption} />
                                </Col>
                              )
                            } else { if (i.id !== "1" && i.id !== "2" && i.active === "1") console.log(i.type, i); }
        
                            return formattedInput;
                          })}
                        </Row>
                      )
                    })}
                    {form === 'dl' && step === "1" ? 
                      <Row className="order-form-input-group py-2 mb-2">
                        <Col sm="6">
                          <FormGroup>
                            <Label>Customer Name</Label>
                            <Input
                              type="text"
                              name="customer_name"
                              placeholder="Customer Name"
                              value={props.order.customer_name}
                              onChange={(e) => props.setCustomerName(e)}
                            />
                          </FormGroup> 
                        </Col>
                      </Row>
                    : null}
                    {form === 'dl' ?
                      <Row className="order-form-input-group py-2 mb-2">
                        <Col>
                          <FormGroup>
                            <Label>Notes</Label>
                            <Input
                              type="textarea"
                              name="note"
                              placeholder="Notes"
                              value={page_note}
                              onChange={(e) => props.setNote(e, step)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    : null }
                  </Container>
                </Form>
              </Col>
            </Row>
            <FormNavBottom
              order={props.order}
              handleSelectOption={props.handleSelectOption}
            />
          </CardBody>
        </Card>
      </Row>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>{modalProps.title}</ModalHeader>
        <ModalBody>
          <p>{modalProps.body_text}</p>
          {modalProps.img_path !== null ? <img className="card-img-top" src={process.env.REACT_APP_IMG_URL+'/'+modalProps.img_path} alt={modalProps.name} /> : null}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Close</Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
}

export default OrderFormPage;