import React from 'react';
import { 
  Col,
  FormGroup,
  Label,
  Input
} from 'reactstrap';
import Price from '../Price';
import './Checkbox.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const Checkbox = (props) => {
  return (
    <FormGroup className="order-form-checkbox">
      <Col className="text-dark pl-0">
        <FormGroup className="d-inline" check>
          <Label check>
            <Input type="checkbox" disabled={props.disabled} checked={props.input.checked} onChange={(e) => props.handleSelectOption(props.input, e.target.checked)} />{' '}
            <span className="text-dark">{props.input.name}</span><Price price={props.input.price} />
          </Label>
        </FormGroup>
        {props.toggleTooltip !== null ? <FontAwesomeIcon className="order-form-tooltip" icon={faInfoCircle} onClick={props.toggleTooltip} /> : null}
        {props.img_url !== null ? <img src={props.img_url} alt={props.input.name} /> : null}
      </Col>
    </FormGroup>
  );
}

export default Checkbox;