import _ from 'lodash';

export const initFormOptions = (options, rules, rule_groups, rule_group_conditions, order = null) => {
  let initializedOptions = options;
  let initializedRuleGroups = rule_groups;

  _.each(initializedOptions, function(input) {
    input.rules = _.filter(rules, function(r) {
      return r.x_id === input.id && r.x_type !== "Group";
    });
    // Convert ordering field to int
    input.input_group = parseInt(input.input_group, 10);
    input.input_order = parseInt(input.input_order, 10);
    // Set default selected field
    if (input.type === "Dropdown") {
      input.selected_option = null;
      _.each(input.options, function(o) {
        o.option_order = parseInt(o.option_order, 10);
      });
    }
    input.initial_option = null;
    if (input.type === "Checkbox") input.checked = false;
  });

  _.each(initializedRuleGroups, function(rg) {
    let formatted_conditions = [];
    let combine_conditions = false;
    let or_group = {
      conditions: [],
      is_true: false
    };

    rg.conditions = _.filter(rule_group_conditions, {'rule_group_id': rg.id});

    _.each(rg.conditions, function(rgc, index) {
      // Convert ordering field to int
      rgc.condition_order = parseInt(rgc.condition_order, 10);
      // find option with conditional and set has_rule_group
      let o = _.find(options, {'id': rgc.x_id});
      if (o !== undefined && o.has_rule_group === undefined) {
        o.has_rule_group = true;
      }
      if (rgc.conditional === "or") {
        combine_conditions = true;
        or_group.conditions.push(rgc)
      }
      // check if it's an and
      if (rgc.conditional === "and" && combine_conditions === false) {
        rgc.is_true = false;
        formatted_conditions.push(rgc);
      }
      if ((rgc.conditional === "and" && combine_conditions === true) || index === (rg.conditions.length-1)) {
        // We've reached end of or group
        combine_conditions = false;
        or_group.conditions.push(rgc);
        formatted_conditions.push(or_group);
        or_group = {
          conditions: [],
          is_true: false
        };
      }
    });
   
    // Sort conditions
    rg.conditions = _.orderBy(formatted_conditions, ['condition_order'], ['asc']);
    rg.rules = _.filter(rules, {'x_id': rg.id, 'x_type': 'Group'});
    rg.y_value = 0;
  });

  if (order !== null) {
    console.log("ORDER: ", order);
    let bw = _.find(initializedOptions, {'id': '142'});
    if (order.build_week_new !== null) {
      let sbw = _.find(bw.options, {'avalon_name': order.build_week_new});
      if (sbw !== undefined) bw.selected_option = sbw.id;
    }
    _.each(order.options, function(o) {
      // option_id, option_dropdown_id
      let foundInput = _.find(initializedOptions, {'id': o.option_id});
      if (foundInput !== undefined) {
        foundInput.order_option_id = o.order_option_id;
        if (foundInput.type === "Dropdown") {
          foundInput.selected_option = _.find(foundInput.options, {'id': o.option_dropdown_id});
          if (foundInput.selected_option !== undefined) {
            foundInput.selected_option.price = o.price;
            foundInput.initial_option = o.option_dropdown_id;
          } else {
            console.log("Couldn't find option: ", foundInput.selected_option)
          }
          // Apply rules
          if (foundInput.rules !== undefined) initializedOptions = checkOptionRules(foundInput, foundInput.selected_option, initializedOptions);
          if (foundInput.has_rule_group !== undefined && foundInput.has_rule_group) initializedRuleGroups = checkRuleGroups(foundInput, initializedOptions, initializedRuleGroups);
          // Reset selected option to id instead of object
          foundInput.selected_option = o.option_dropdown_id;
          if (foundInput.id == "159") console.log("ACCENT COLOR: ", o.option_dropdown_id, foundInput)
        }
        if (foundInput.type === "Checkbox") {
          foundInput.checked = true;
          if (foundInput.rules.length !== 0) initializedOptions = checkOptionRules(foundInput, null, initializedOptions);
          if (foundInput.has_rule_group !== undefined && foundInput.has_rule_group) initializedRuleGroups = checkRuleGroups(foundInput, initializedOptions, initializedRuleGroups);
          if (foundInput !== undefined) foundInput.price = o.price;
          foundInput.selected_option = true;
        }
      }
    });
  }
  console.log("LOADED ORDER: ", initializedOptions)
  return {initializedOptions, initializedRuleGroups, price: calculatePrice(initializedOptions)};
}

export const applyRule = (rule, input, dropdown = null) => {
  if (input === undefined) {
    // console.log("INPUT NOT FOUND: ", rule, input, dropdown)
  } else {
    switch(rule.change_type) {
      case "active":
        input.active = rule.change_value;
        input.active_rule = {rule_id: rule.id, input_id: rule.x_id, y_value: rule.y_value};
        // Reset option
        if (input.type === "Checked") input.checked = false;
        if (input.type === "Dropdown") input.selected_option = null;
        break;
      case "price":
        // Check if the default_price has been set yet
        if (input.default_price === undefined) input.default_price = input.price;
        input.price = rule.change_value;
        input.price_rule = {rule_id: rule.id, input_id: rule.x_id, y_value: rule.y_value, change_value: rule.change_value};
        break;
      case "default":
        // if (dropdown === null) console.log("DEFAULT: ", rule, input, dropdown)
        input.default = rule.change_value;
        input.default_rule = {rule_id: rule.id, input_id: rule.x_id, y_value: rule.y_value};
        if (input.type === "Checkbox") {input.checked = true;}
        // When initializing a default rule we don't want to override an option if already selected
        // if (dropdown !== null && dropdown.selected_option !== null) break;
        // console.log("DEFAULT RULES: ", dropdown, rule.z_dropdown_id)
        if (dropdown !== null) {
          dropdown.initial_option === null || dropdown.initial_option !== dropdown.selected_option ? dropdown.selected_option = rule.z_dropdown_id : input.checked = true;
        }
        
        if (dropdown === null && input.type === "Dropdown") input.selected_option = null;
        // toggleWarning(input, false);
        break;
      case "disabled":
        // If the option is selected, unselect it on disable
        if (input.type === "Dropdown" && input.selected_option !== undefined && input.selected_option !== null) input.selected_option = null;
        if (input.type === "Checkbox" && input.checked !== undefined && input.checked === true) input.checked = false;
        input.disabled = rule.change_value;
        input.disabled_rule = {rule_id: rule.id, input_id: rule.x_id, y_value: rule.y_value};
        // Check for soft warnings
        // toggleWarning(input, false);
        break;
      case "img_path":
        // console.log("IMG PATH RULE: ", input, rule);
        if (input.default_img_path === undefined) input.default_img_path = input.img_path;
        input.img_path = rule.change_value;
        input.img_path_rule = {rule_id: rule.id, input_id: rule.x_id, y_value: rule.y_value};
        break;
      case "img_thumb_path":
        // console.log("IMG THUMB PATH RULE: ", input, rule);
        if (input.default_img_thumb_path === undefined) input.default_img_thumb_path = input.img_thumb_path;
        input.img_thumb_path = rule.change_value;
        input.img_thumb_path_rule = {rule_id: rule.id, input_id: rule.x_id, y_value: rule.y_value};
        break;
      case "limit":
        console.log("LIMIT: ", input, rule, dropdown);
        dropdown.limit = rule.change_value;
        dropdown.limit_rule = {rule_id: rule.id, input_id: rule.x_id, y_value: rule.z_dropdown_id, type: rule.change_value};
        break;
      default:
        break;
    }
  }
}

export const undoRule = (input, type) => {
  switch(type) {
    case "active":
      input.active = "1";
      input.active_rule = null;
      break;
    case "price":
      input.price = input.default_price;
      input.price_rule = null;
      // updatePagePrice();
      break;
    case "default":
      input.default = null;
      input.default_rule = null;
      if (input.type === "Dropdown") input.selected_option = null;
      if (input.type === "Checkbox") input.checked = false;
      // toggleWarning(option, true);
      break;
    case "disabled":
      input.disabled = null;
      input.disabled_rule = null;
      // toggleWarning(option, true);
      break;
    case "img_path":
      input.img_path = input.default_img_path;
      input.img_path_rule = null;
      break;
    case "img_thumb_path":
      input.img_thumb_path = input.default_img_thumb_path;
      input.img_thumb_path_rule = null;
      break;
    case "limit":
      input.limit = null;
      input.limit_rule = null;
      break;
    default:
      break;
  }
}

// o = option, ui = updated_input
export const undoOldRules = (o, ui) => {
  // console.log(o, ui)
  // if (o.default_rule !== undefined) console.log("DEFAULT RULE UNDO: ", o, ui)
  // Set default to 0 to catch Dropdown input options itself
  let y_value = 0;
  if (ui.type === "Dropdown" && ui.selected_option !== undefined && ui.selected_option !== null) y_value = ui.selected_option.id;
  // Check the option itself
  if (o.active_rule !== undefined && o.active_rule !== null && o.active_rule.input_id === ui.id && o.active_rule.y_value !== y_value) undoRule(o, "active");
  if (o.price_rule !== undefined && o.price_rule !== null && o.price_rule.input_id === ui.id && o.price_rule.y_value !== y_value) undoRule(o, "price");
  if (o.disabled_rule !== undefined && o.disabled_rule !== null && o.disabled_rule.input_id === ui.id && ui.selected_option !== y_value) undoRule(o, "disabled");
  if (o.default_rule !== undefined && o.default_rule !== null && o.default_rule.input_id === ui.id && ui.selected_option !== y_value) undoRule(o, "default");

  // If dropdown check dropdown options
  if (o.type === "Dropdown") {
    _.each(o.options, function(ddo) {
      if (ddo.active_rule !== undefined && ddo.active_rule !== null && ddo.active_rule.input_id === ui.id && ddo.active_rule.y_value !== y_value) {undoRule(ddo, "active");}
      if (ddo.price_rule !== undefined && ddo.price_rule !== null && ddo.price_rule.input_id === ui.id && ddo.price_rule.y_value !== y_value) undoRule(ddo, "price");
    });
  }
}

export const checkRuleGroups = (input, options, rule_groups) => {
  let updated_rule_groups = {
    activated: [],
    deactivated: []
  }
  // if (input.id === "185") console.log("HERE 3: ", input, options, rule_groups)
  _.each(rule_groups, function(rg) {
    let rule_group_true = true;
    _.each(rg.conditions, function(rgc) {
      if (rgc.conditions !== undefined) {
        // we have or group
        let or_group_is_true = false;
        let found_oc = 0;
        let update_or_group = false;
        _.each(rgc.conditions, function(oc) {
          let is_true = false;
          if (oc.x_type === "Dropdown" && oc.y_value === "0") {
            // Check option
            let fi = _.find(options, {'id': oc.x_id});
            if (fi.selected_option === undefined || fi.selected_option === null) is_true = true;
            if (is_true) or_group_is_true = true;
            found_oc++;
            update_or_group = true;
          }
          
          if (input.id === oc.x_id) {            
            // we have match determine rule type
            if (oc.x_type === "Dropdown") is_true = (((input.selected_option !== null && input.selected_option !== "") && input.selected_option.id === oc.y_value) || ((input.selected_option === null || input.selected_option === "" || input.selected_option === undefined) && oc.y_value === 0));
            if (oc.x_type !== "Dropdown") is_true = (input.selected_option === oc.y_value);
            // only update if true
            if (is_true) or_group_is_true = true;
            found_oc++;
            update_or_group = true;
          } else {
            //  Find option
            let dd = _.find(options, {'id': oc.x_id});
            // we have match determine rule type
            if (dd !== undefined) {
              
              if (oc.x_type === "Dropdown") is_true = (((dd.selected_option !== null && dd.selected_option !== "") && dd.selected_option === oc.y_value) || ((dd.selected_option === null || dd.selected_option === "" || dd.selected_option === undefined) && oc.y_value === 0));
              if (oc.x_type !== "Dropdown") is_true = (dd.selected_option === oc.y_value);
              // only update if true
              if (is_true) or_group_is_true = true;
              found_oc++;
              update_or_group = true;
            }
          }
        });
        if (update_or_group) rgc.is_true = (found_oc > 0 && or_group_is_true);
      } else {
        // check if rule matches option
        if (input.id === rgc.x_id) {
          // we have match determine rule type
          if (rgc.x_type === "Dropdown") {
            rgc.is_true = ((input.selected_option !== null && input.selected_option !== "") && input.selected_option.id === rgc.y_value);
          }
          if (rgc.x_type !== "Dropdown") rgc.is_true = (input.selected_option === rgc.y_value);
        } else {
          let dd = _.find(options, {'id': rgc.x_id});
          if (dd !== undefined) {
            if (rgc.x_type === "Dropdown") {
              rgc.is_true = ((dd.selected_option !== null && dd.selected_option !== "") && dd.selected_option === rgc.y_value);
            }
          }
        }
        // Check for unselected options
        if (rgc.x_type === "Dropdown" && rgc.y_value === "0") {
          // Check option     
          let fi = _.find(options, {'id': rgc.x_id});
          if (fi.selected_option === undefined || fi.selected_option === null) rgc.is_true = true;
        }
      }

      if (!rgc.is_true) rule_group_true = false;
    });
    if (rg.id === "453") console.log(rg, rule_group_true)
    if (rule_group_true && !rg.is_true) {
      rg.is_true = rule_group_true;
      // console.log("ACTIVATE RULE GROUP: ", rg);
      updated_rule_groups.activated.push(rg);
      let inactive_option = false;
      _.each(rg.rules, function(rgr) {
        let option = _.find(options, {'id': rgr.z_id});
        
        if (option !== undefined) {
          let update_option = rgr.z_dropdown_id !== "0" ? _.find(option.options, {'id': rgr.z_dropdown_id}) : option;
          rgr.z_dropdown_id !== "0" && option.type !== "Checkbox" ? applyRule(rgr, update_option, option) : applyRule(rgr, update_option);
        } else {
          if (inactive_option === false) {
            console.log("Probable inactive option: ", rgr.z_id, rgr);
            inactive_option = true;
          }
        }
      });
    }
    
    if (rg.is_true && !rule_group_true) {
      rg.is_true = rule_group_true;
      // console.log("DEACTIVATE RULE GROUP: ", rg);
      updated_rule_groups.deactivated.push(rg);
      _.each(rg.rules, function(rgr) {
        var option = _.find(options, {'id': rgr.z_id});
        if (option !== undefined) {
          var update_option = rgr.z_dropdown_id !== "0" ? _.find(option.options, {'id': rgr.z_dropdown_id}) : option;
          if (update_option !== undefined) {
            switch (rgr.change_type) {
              case 'active':
                if (rgr.id === update_option.active_rule.rule_id) undoRule(update_option, rgr.change_type);
                break;
              case 'default':
                if (rgr.id === update_option.default_rule.rule_id) {
                  undoRule(update_option, rgr.change_type);
                }
                break;
              case 'limit':
                undoRule(option, rgr.change_type);
                break;
              default:
                return;
            }
          }
        } else {
          console.log("Probable inactive option: ", rgr.z_id, rgr);
        }
      });
    }
  });

  console.log("UPDATED RULE GROUPS: ", updated_rule_groups);
  return rule_groups;
}

export const checkOptionRules = (i, so = null, options) => {
  var optionRules = [];
  i.selected_option = so;
  if (i.type === "Checkbox") i.selected_option = i.checked ? "1" : "0";
  if (i.type === "Dropdown") {
    // Get dropdown input rules
    var dd_input_rules = _.filter(i.rules, {'y_value': 0});
    // console.log("Input Rules: ", dd_input_rules);
    // console.log("Selected option rules: ", _.filter(i.rules, {'y_value': so.id}));
    // Get option rules checking for selected option
    var dd_option_rules = [];
    // If dropdown option selected get any rules
    // console.log(i, so)
    if (i.selected_option !== undefined && i.selected_option !== null && i.selected_option !== "") dd_option_rules = _.filter(i.rules, {'y_value': so.id});
    // console.log("Dropdown Input Rules: ", dd_input_rules);
    // console.log("Dropdown Option Rules: ", dd_option_rules);
    optionRules = dd_input_rules.concat(dd_option_rules);
  } else {
    optionRules = i.rules;
    console.log("CHECKBOX RULES: ", optionRules);
  }

  // Update each option
  _.each(options, function(o) {
    // Undo old rules
    undoOldRules(o, i);
    // Check each rule
    // console.log("PART 1: ", optionRules)
    _.each(optionRules, function(r) {
      // Determine type of rule
      // Is it affecting a drop down option?
      if (o.type === "Dropdown" && r.z_id === o.id && r.z_dropdown_id !== "0") {
        let ao = null;
        // let debug = false;
        // Make sure we should be applying the rule
        // This is for dropdowns that don't have a selected option yet
        if (i.selected_option === undefined && i.type === "Dropdown") {
          ao = _.find(o.options, {id: r.z_dropdown_id});
          if (ao !== undefined) applyRule(r, ao, o);
        }
        if ((i.selected_option !== undefined && r.x_type === "Dropdown" && r.y_value === i.selected_option.id) 
          || (r.x_type !== "Dropdown" && r.y_value === i.selected_option)) {
          // Find the affected option
          ao = _.find(o.options, {id: r.z_dropdown_id});
          // ao !== undefined ? applyRule(r, ao, o) : debug = true;
          if (ao !== undefined) applyRule(r, ao, o);
          // if (debug) console.log("Affected Option was undefined: ", so, r, ao, o);
        }

        if (r.x_type === "Checkbox" && i.checked === true && r.y_value === "1") {
          if (r.z_dropdown_id === "0" && r.z_id === o.id) {
            // console.log("APPLYING CHECKBOX RULE TO DROPDOWN OPTION: ",i,r,o);
            applyRule(r, o);
          }

          if (r.z_dropdown_id !== "0" && r.z_id === o.id) {
            ao = _.find(o.options, {id: r.z_dropdown_id});
            if (ao !== undefined) {
              applyRule(r, ao, o);
              // console.log("Checkbox rule applied to dropdown: ",r, ao, o) 
            }
          }
        }
      }

      // Or is it an input rule
      var dd_option_selected = (i.selected_option !== undefined && i.selected_option !== null);
      // Prevent group rules applying here
      if (r.x_type !== "Group" && r.z_dropdown_id === "0") {
        if ((r.x_type === "Dropdown" && r.z_id === o.id && !dd_option_selected) 
          || (r.x_type === "Dropdown" && r.z_id === o.id && dd_option_selected && r.y_value === i.selected_option.id)
          || (r.x_type !== "Dropdown" && r.y_value === i.selected_option && r.z_id === o.id)) {
          // console.log(r, o)
          // We have a input rule
          // console.log("APPLY INPUT RULE: ", r, o);
          applyRule(r, o);
        }
      }
      
    });
  });
  return options;
}

export const calculatePrice = (options) => {
  let price = 0;
  _.each(options, function(o) {
    if (o.disabled !== "1" && o.active === "1") {
      if (o.type === "Dropdown" && o.selected_option !== null && o.selected_option !== undefined && o.selected_option !== "") {
        let so_id = o.selected_option.id !== undefined ? o.selected_option.id : o.selected_option;
        let so = _.find(o.options, {'id': so_id});
        // console.log(o, so);
        if (so !== undefined) price += parseInt(so.price, 10);
      }
  
      if (o.type === "Checkbox" && o.checked) {
        // console.log(o)
        price += parseInt(o.price, 10);
      }
    }
  });

  return price;
}

export const initialState = {
  price: 0,
  options: [],
  rule_groups: [],
  pages: [
    {id: 1, name: 'Initial Boat Build', note: ''},
    {id: 2, name: 'Packages', note: ''},
    {id: 3, name: 'Performance/Pontoon', note: ''},
    {id: 4, name: 'Deck Features', note: ''},
    {id: 5, name: 'Stand Features', note: ''},
    {id: 6, name: 'Wall Features', note: ''},
    {id: 7, name: 'Furniture Features', note: ''},
    {id: 8, name: 'Canvas/Top', note: ''}
  ],
  customer_name: "",
  show_required: false
}

export const orderReducer = (state, action) => {
  switch (action.type) {
    case 'INIT_FORM': 
      return {
        ...state,
        options: action.options,
        rule_groups: action.rule_groups,
        price: action.price !== undefined ? action.price : 0,
        customer_name: action.customer_name
      }
    case 'INIT_FORM_WITH_PAGES': 
      return {
        ...state,
        options: action.options,
        rule_groups: action.rule_groups,
        price: action.price !== undefined ? action.price : 0,
        customer_name: action.customer_name,
        customer_phone: action.customer_phone,
        customer_email: action.customer_email,
        customer_zipcode: action.customer_zipcode,
        customer_contact_preference: action.customer_contact_preference,
        order_status: action.order_status,
        order_source: action.order_source,
        id: action.id,
        pages: action.pages
      }
    case 'UPDATE_FORM':
      return {
        ...state,
        options: action.options,
        rule_groups: action.rule_groups,
        price: action.price
      }
    case 'SET_SELECTED_OPTION':
      return {
        ...state,
        options: state.options.map((o) => {
          if (o.id === action.input.id) {
            return {
              ...o,
              selected_option: action.selected_option
            }
          } else {
            return o;
          }
        })
      }
    case 'SET_CUSTOMER_NAME':
      return {
        ...state,
        customer_name: action.customer_name
      }
    case 'SET_NOTE':
      return {
        ...state,
        pages: state.pages.map((p) => {
          if (p.id === action.step) {
            return {
              ...p,
              note: action.note
            };
          } else {
            return p;
          }
        })
      }
    case 'SET_PRICE':
      return {
        ...state,
        price: action.price
      }
    case 'TOGGLE_SHOW_REQUIRED':
      return {
        ...state,
        show_required: action.show_required
      }
    default:
      return state;
  }
}