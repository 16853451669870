import React, { useState } from 'react';
import {
  Col,
  Row,
  Button
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useParams, useHistory } from 'react-router-dom';
import _ from 'lodash';

const FormNavBottom = (props) => {
  const { step, form, brand, model, floorplan, userId, dealershipId, orderId} = useParams();
  const history = useHistory();
  const [navigatingBack, setNavigatingBack] = useState(false);
  const modelInput = props.order.options !== undefined ? _.find(props.order.options, {'id': '1'}) : null;
  const floorplanInput = props.order.options !== undefined ? _.find(props.order.options, {'id': '2'}) : null;
  const base_url = (userId !== undefined) ? `/at/${userId}/${dealershipId}/${orderId}/${form}/${brand}` : `/${form}/${brand}`;
  const next_url = step !== undefined && parseInt(step, 10) < 8 ? `${base_url}/m/${model}/f/${floorplan}/s/${parseInt(step, 10)+1}` : `${base_url}/m/${model}/f/${floorplan}/review`;
  
  const createBackUrl = () => {
    if (model !== undefined && floorplan !== undefined) {
      if (step === undefined) {
        return `${base_url}/m/${model}/f/${floorplan}/s/8`;
      } else {
        let parsed_step = parseInt(step, 10);
        return parsed_step === 1 ? `${base_url}/m/${model}/f` : `${base_url}/m/${model}/f/${floorplan}/s/${parsed_step-1}`;
      }
    } else if (model !== undefined && floorplan === undefined) {
      return `${base_url}/m`;
    } else { return `${base_url}/m` }
  }
  const back_url = createBackUrl();

  const goBack = () => {
    setNavigatingBack(true);
    if (step === "1")  props.handleSelectOption(floorplanInput, "");
    if (back_url === `${base_url}/m`) props.handleSelectOption(modelInput, "");

    history.push(back_url);
  }

  const requiredOptionCheck = () => {
    let requiredOptions = _.filter(props.order.options, function(o) {
      if (o.required === "1" && o.disabled !== "1" && o.selected_option === null) return o;
    })
    return (requiredOptions.length > 0 && step === "8");
  }

  return (
    <Row className="py-2 mx-0 mt-4" noGutters>
      <Col>
        <Button onClick={() => goBack()} className="btn btn-dark float-left" disabled={model === undefined}><FontAwesomeIcon icon={faChevronLeft} /> Back</Button>
      </Col>
      <Col>
        <Button onClick={() => history.push(next_url)} className="btn btn-dark float-right" disabled={floorplan === undefined || requiredOptionCheck()}>Next <FontAwesomeIcon icon={faChevronRight} /></Button>
      </Col>
    </Row>
  );
}

export default FormNavBottom;