import React, { useContext } from 'react';
import {
  ListGroup,
  Badge,
  Row,
  Button,
  Col
} from 'reactstrap';
import { useParams, Link, useHistory } from 'react-router-dom';
import './FormNav.sass';
import _ from 'lodash';
import { OrderContext } from '../../../../index'

const FormNav = (props) => {
  const { step, form, brand, model, floorplan, userId, dealershipId, orderId} = useParams();
  const base_url = (userId !== undefined) ? `/at/${userId}/${dealershipId}/${orderId}/${form}/${brand}/m/${model}/f/${floorplan}` : `/${form}/${brand}/m/${model}/f/${floorplan}`;
  const history = useHistory();
  const requiredOptionsCheck = _.filter(props.order.options, function(o) {
    const checkboxCheck = (o.type === "Checkbox" && o.checked === false);
    const dropdownCheck = (o.type === "Dropdown" && (o.selected_option === null || o.selected_option === ""));
    if (o.required === "1" && (checkboxCheck || dropdownCheck) && o.disabled !== "1" && o.id !== "80") return o;
  });

  const orderContext = useContext(OrderContext)

  const handleReviewClick = () => {
    if (requiredOptionsCheck.length === 0) {
      history.push(`${base_url}/review`)
    } else {
      if (orderContext.orderState.show_required === false) orderContext.orderReducer({type: "TOGGLE_SHOW_REQUIRED", show_required: true})
      history.push(`${base_url}/s/${requiredOptionsCheck[0].page}`)
    }
  }

  return (
    <div className="order-form-page-nav sticky-top d-none d-sm-block">
      <ListGroup>
        {props.pages.map(p => {
          let numRequiredOptions = _.filter(props.order.options, function(o) {
            const checkboxCheck = (o.type === "Checkbox" && o.checked === false);
            const dropdownCheck = (o.type === "Dropdown" && (o.selected_option === null || o.selected_option === ""));
            if (o.required === "1" && parseInt(o.page, 10) === p.id && (checkboxCheck || dropdownCheck) && o.disabled !== "1" && o.id !== "80") return o;
          }).length;
          return (
            <Link
            key={p.id}
            to={`${base_url}/s/${p.id}`}
            className={(parseInt(step, 10) === p.id ? 'active font-weight-bold list-group-item' : 'list-group-item text-dark')}>
            {p.name} {numRequiredOptions > 0 ? <Badge pill color="primary">{numRequiredOptions}</Badge> : null}</Link>
          );
        })}
      </ListGroup>
      <Row className="mt-2" noGutters>
        <Col sm="12">
          <Button 
            onClick={() => handleReviewClick()}
            block
            color={requiredOptionsCheck.length === 0 ? "primary" : "dark"}
          >
          Review and Get Quote</Button>
        </Col>
      </Row>
    </div>
  );
}

export default FormNav;