import React from 'react';
import {
  Card,
  CardBody
} from 'reactstrap';
import './Option.sass';

const PrimaryOptionHeader = (props) => {
  return (
    <CardBody>
      <h5 className="card-title mb-0">{props.title}</h5>
      { props.range !== null ? <h6 className="card-subtitle text-muted mt-1" style={{fontSize: '12px'}}>Starting at ${props.range.min.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}&nbsp;-&nbsp;${props.range.max.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h6> : null }
      { props.price !== null ? <h6 className="card-subtitle text-muted mt-1" style={{fontSize: '12px'}}>Starting at ${props.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h6> : null }
    </CardBody>
  );
}

const Option = (props) => {
  const img = props.img;
  return (
    <Card className="primary-option shadow-sm" onClick={() => props.handleSelectOption()}>
      <PrimaryOptionHeader {...props} />
      { img !== undefined && img !== null ?
        <div className="primary-img img-thumbnail" style={{backgroundImage: `url(${img.src})`}}></div>
      : <div className="primary-img img-thumbnail"></div> }
    </Card>
  );
}

export default Option;