import React, { useState } from 'react';
import {
  Card,
  CardBody,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Button,
  Input,
  Alert
} from 'reactstrap';
import Price from '../FormPage/Components/Price';
import './Review.sass';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCheck } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { Redirect, useParams } from 'react-router-dom';

const Review = (props) => {
  let pages = props.order.pages;
  let options = props.order.options;
  const { brand, userId, dealershipId, orderId } = useParams();
  
  const model = _.find(props.order.options, {'id': '1'});
  const selected_model = _.find(model.options, {'id': model.selected_option});

  const initialFormState = {
    customer_name: '',
    customer_zipcode: '',
    customer_email: '',
    customer_phone: '',
    loading: false,
    contact_preference: 'Email',
    form_step: 1,
    dealership_id: null,
    dealerships: []
  };

  const [quoteForm, setQuoteForm] = useState(initialFormState);

  const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
      
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  const findClosestDealerships = async () => {
    setQuoteForm({...quoteForm, loading: true});
    let data = {
      location: null,
      brand: (brand === "tahoe" ? 'Tahoe' : 'Avalon')
    };

    // Is there a zipcode
    if (quoteForm.customer_zipcode !== "" && userId === undefined) {
      const google_maps_url = `https://maps.google.com/maps/api/geocode/json?address=${quoteForm.customer_zipcode}&sensor=false&key=AIzaSyCFa-Bf0znBnJNrqv00UgpMfbA9JFgskYs`;
      await axios.get(google_maps_url).then((res) => {
        data.location = res.data.results[0].geometry.location;
      });
    }

    const dealerships_url = `${process.env.REACT_APP_API_URL}/v3/order/dealers`;
    await axios.post(dealerships_url, data).then((res) => {
      console.log(res.data);
      setQuoteForm({...quoteForm, loading: false, form_step: 3, dealerships: res.data.dealerships});
    }).catch((error) => {
      console.log(error);
    });
  }

  const submitQuote = async (dealership_id = null) => {
    if (dealership_id !== null) {
      setQuoteForm({...quoteForm, form_step: 2, dealership_id: dealership_id, loading: true});
    } else {
      setQuoteForm({...quoteForm, loading: true});
    }
    
    let data = {
      total_price: props.order.price,
      order_options: [],
      user_id: 0
    }
    console.log("USER ID: ", userId)
    // Check if customer or dealer
    if (userId === undefined) {
      data.customer_name = quoteForm.customer_name;
      data.customer_email = quoteForm.customer_email;
      data.customer_phone = quoteForm.customer_phone;
      data.customer_zipcode = quoteForm.customer_zipcode;
      data.contact_preference = quoteForm.contact_preference;
      data.location = {};
      data.order_source = 2;
      data.dealership_id = dealership_id;
      data.build_week = null;
    } else {
      data.user_id = userId;
      data.dealership_id = dealershipId;
      data.order_id = orderId;
      data.order_source = 1;
      data.order_status = 0;
      data.confirmed = false;
      data.customer_name = props.order.customer_name;
      data.page_1 = props.order.pages[0].note;
      data.page_2 = props.order.pages[1].note;
      data.page_3 = props.order.pages[2].note;
      data.page_4 = props.order.pages[3].note;
      data.page_5 = props.order.pages[4].note;
      data.page_6 = props.order.pages[5].note;
      data.page_7 = props.order.pages[6].note;
      data.page_8 = props.order.pages[7].note;
      let bw = _.find(options, {'id': '142'});
      let formatted_bw = _.find(bw.options, {'id': bw.selected_option});
      data.build_week = formatted_bw.avalon_name;
    }

    console.log(data, dealership_id);

    // Format Selected Options
    let formatted_options = [];
    let selected_options = _.filter(options, (o) => {
      const dropdown_check = (o.selected_option !== null && o.selected_option !== undefined);
      const checkbox_check = (o.checked !== undefined && o.checked === true);
      if (o.id === "1") {
        let m = _.find(o.options, {'id': o.selected_option});
        data.model = brand === 'tahoe' ? m.tahoe_name : m.avalon_name;
      }
      if (o.id === "2") {
        let f = _.find(o.options, {'id': o.selected_option});
        data.floorplan = f.avalon_name;
      }
      if ( dropdown_check || checkbox_check ) {
        let formatted_option = {
          option_id: o.id,
          option_dropdown_id: 0,
          option_name: o.name,
          option_dropdown_name: null
        }
        if (checkbox_check) formatted_option.price = o.price;
        if (dropdown_check) {
          formatted_option.option_dropdown_id = o.selected_option;
          let selected_option = _.find(o.options, {'id': o.selected_option});
          formatted_option.price = selected_option.price;
          formatted_option.option_dropdown_name = brand === 'tahoe' && o.id === "1" ? selected_option.tahoe_name : selected_option.avalon_name;
        }
        if (o.id !== "142") formatted_options.push(formatted_option);
        return o;
      }
    });
    console.log("SUBMITTING QUOTE: ", data, selected_options, formatted_options);
    data.order_options = formatted_options;
    
    if (data.order_options.length > 0) {
      const order_url = `${process.env.REACT_APP_API_URL}/v3/order`;
      axios.post(order_url, data).then((res) => {
        if (res.status === 200) {
          console.log(res.data)
          if (res.data.base64 !== undefined) {
            const d = (new Date()).toISOString().substring(0, 10);
            const contentType = 'application/pdf';
            const b64data = res.data.base64;
            const blob = b64toBlob(b64data, contentType);
            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(blob);
              return;
            } 
            // For other browsers: 
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(blob);
            let link = document.createElement('a');
            link.href = data;
            link.download="Quote-"+res.data.order_id+"-"+(brand === "tahoe" ? selected_model.tahoe_name : selected_model.avalon_name)+"-"+d.replace(" ","");;
            link.click();
            setTimeout(function(){
              // For Firefox it is necessary to delay revoking the ObjectURL
              window.URL.revokeObjectURL(data);
            }, 100);
          } else { console.log(res.data); }
          
          setQuoteForm({...quoteForm, form_step: 2, loading: false});

          if (userId !== undefined) {
            setTimeout(function() {
              window.parent.parent.window.location = `${process.env.REACT_APP_DOMAIN}/#/Order/`;
            }, 2000);
          }
        }
      }).catch((error) => {
        console.log(error);
      });
    } else {
      console.log(data)
    }
  }

  const updateQuote = async () => {
    setQuoteForm({...quoteForm, form_step: 2, dealership_id: dealershipId, loading: true});
    let create = _.filter(props.order.options, function(o) {
      if (o.type === "Checkbox") {
        return o.order_option_id === undefined && o.checked === true;
      }
      if (o.type === "Dropdown") {
        return o.order_option_id === undefined && o.selected_option !== null && o.selected_option !== undefined && o.id !== '142';
      }
      return;
    });
    let remove = _.filter(_.reject(props.order.options, 'selected_option'), 'order_option_id');
    let update = _.filter(props.order.options, 'order_option_id');

    _.each(create, function(o) {
      o.rules = null;
      o.option_name = o.name;
      o.option_dropdown_name = null;
      if (o.type === "Checkbox") {
        o.selected_option = 0;
      } 

      if (o.type === "Dropdown") {
        let selected_option = _.find(o.options, {'id': o.selected_option});
        o.option_dropdown_name = brand === 'tahoe' && o.id === "1" ? selected_option.tahoe_name : selected_option.avalon_name;
        o.formatted_options = null;
      }
    });

    _.each(update, function(o) {
      o.rules = null;
      o.option_name = o.name;
      o.option_dropdown_name = null;
      if (o.type === "Checkbox") {
        o.selected_option = 0;
      }

      if (o.type === "Dropdown") {
        if (o.selected_option !== null) {
          let selected_option = _.find(o.options, {'id': o.selected_option});
          o.option_dropdown_name = brand === 'tahoe' && o.id === "1" ? selected_option.tahoe_name : selected_option.avalon_name;
          o.formatted_options = null;
        } else {
          remove.push(o);
        }
      }
    });

    _.each(remove, function(o) {
      o.rules = null;
    });

    let bw = _.find(options, {'id': '142'});
    let formatted_bw = _.find(bw.options, {'id': bw.selected_option});
  
    let data = {
      create: create,
      remove: remove,
      update: update,
      user_id: userId,
      dealership_id: dealershipId,
      total_price: props.order.price,
      order_id: orderId,
      order_source: props.order.order_source,
      order_status: props.order.order_status,
      customer_name: props.order.customer_name,
      customer_phone: props.order.customer_phone,
      customer_email: props.order.customer_email,
      customer_zipcode: props.order.customer_zipcode,
      customer_contact_preference: props.order.customer_contact_preference,
      page_1: props.order.pages[0].note,
      page_2: props.order.pages[1].note,
      page_3: props.order.pages[2].note,
      page_4: props.order.pages[3].note,
      page_5: props.order.pages[4].note,
      page_6: props.order.pages[5].note,
      page_7: props.order.pages[6].note,
      page_8: props.order.pages[7].note,
      build_week: formatted_bw.avalon_name
    };
    
    console.log(data);
    const order_url = `${process.env.REACT_APP_API_URL}/v3/order/update`;

    axios.post(order_url, data).then((res) => {
      if (res.status === 202) {
        console.log("ORDER: ", props.order);
        if (props.order.order_source === '2' && props.order.order_status === '0') {
          // we have a lead quote that is being submitted && duplicated
          const view_order_url = `${process.env.REACT_APP_API_URL}/v3/order?order_id=${props.order.id}`;
          axios.get(view_order_url).then((res) => {
            const lead_order = res.data.order;
            // duplicate order
            const duplicate_order_url = `${process.env.REACT_APP_API_URL}/v2/order/duplicate`;
            axios.post(duplicate_order_url, lead_order).then((res) => {
              if (res.status === 202) {
                console.log("Lead order migrated successfully");
                setQuoteForm({...quoteForm, form_step: 4, loading: false});
              } else {
                console.log(res);
              }
            });
          });
        } else {
          // regular order update
          console.log("Order updated successfully");
          setQuoteForm({...quoteForm, form_step: 2, loading: false});
          setTimeout(function() {
            window.parent.parent.window.location = `${process.env.REACT_APP_DOMAIN}/#/Order/`;
          }, 2000);
        }
      } else {
        console.log(res);
      }
    }).catch((error) => {
      console.log(error);
    });

  }

  _.each(pages, function(p) {
    p.options = _.filter(options, {'page': `${p.id}`});
    p.selected_options = _.filter(p.options, (o) => {
      const dropdown_check = (o.selected_option !== null && o.selected_option !== undefined);
      const checkbox_check = (o.checked !== undefined && o.checked === true);
      if ( dropdown_check || checkbox_check ) return o;
    });
  });

  if (props.order.options.length === 0) return <Redirect to="/" />;
  return (
      <div id="review">
        <Card className="px-4 py-2 review mb-4">
          {quoteForm.form_step === 2 && quoteForm.loading === false ? <div>
            <h3>Your quote has been submitted!</h3>
            <p>Click <a href="atdealer.net/cs/avalon/m">here</a> to start over, or you can go back to <a href="www.avalonpontoons.com">avalonpontoons.com</a></p>
          </div> : pages.map(p => {
            return (p.selected_options !== null && p.selected_options.length > 0) ? (   
              <CardBody key={p.id}>
                <h4>{p.name}</h4>
                <Table className="mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {p.selected_options.map((po,index) => {
                      return (
                        <tr key={po.id}>
                          <td>{index+1}</td>
                          <td>{po.name}{po.type === "Dropdown" ? <span> - {brand === "tahoe" && po.branded === '1' ? _.find(po.options, {'id': po.selected_option}).tahoe_name : _.find(po.options, {'id': po.selected_option}).avalon_name}</span> : null}</td>
                          <td><Price noSeparator price={po.type === "Dropdown" ? _.find(po.options, {'id': po.selected_option}).price : po.price} /></td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
                {p.note !== "" ? 
                  <div>
                    <h5>Notes</h5>
                    <p>{p.note}</p> 
                  </div>
                : null}
              </CardBody>
            ) : null;
          })}
        </Card>
        <Modal isOpen={props.modal} toggle={props.toggle}>
          <ModalHeader toggle={props.toggle}>
            {quoteForm.form_step === 1 && userId === undefined ? "Contact info" : null}
            {quoteForm.form_step === 1 && userId !== undefined && orderId === 'n' ? "Save quote" : null}
            {quoteForm.form_step === 1 && userId !== undefined && orderId !== 'n' ? "Update quote" : null}
            {quoteForm.form_step === 2  || quoteForm.form_step === 4 ? "Quote submitted" : null}
            {quoteForm.form_step === 3 ? "Select a dealership" : null}
          </ModalHeader>
          <ModalBody>
            {userId !== undefined ? 
            <div>
             {quoteForm.form_step === 1 && quoteForm.loading === false && orderId === 'n' ? <Button block color="primary" onClick={() => submitQuote()}>Save Quote</Button> : null}
             {quoteForm.form_step === 1 && quoteForm.loading === true && orderId === 'n' ? <Alert color="primary"><FontAwesomeIcon icon={faSpinner} spin className="mr-3" /> Creating your pdf, this may take 20-30 seconds.</Alert> : null}
             {quoteForm.form_step === 1 && quoteForm.loading === false && orderId !== 'n' ? <Button block color="primary" onClick={() => updateQuote()}>Confirm Update</Button> : null}
             {quoteForm.form_step === 2 && quoteForm.loading === true && orderId !== 'n' ? <Alert color="primary"><FontAwesomeIcon icon={faSpinner} spin className="mr-3" /> Updating your order.</Alert> : null}
            </div>
            : <form name="quote_submit_form">
              {quoteForm.form_step === 1 && quoteForm.loading === false ?
                <div>
                  <FormGroup>
                    <label>Name <span className="text-muted">Required</span></label>
                    <Input
                      type="text"
                      value={quoteForm.customer_name}
                      name="customer_name"
                      onChange={(e) => setQuoteForm({...quoteForm, customer_name: e.target.value})}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label>Email <span className="text-muted">Required</span></label>
                    <Input
                      type="email"
                      value={quoteForm.customer_email}
                      name="customer_email"
                      onChange={(e) => setQuoteForm({...quoteForm, customer_email: e.target.value})}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label>Phone</label>
                    <Input
                      type="text"
                      value={quoteForm.customer_phone}
                      name="customer_phone"
                      onChange={(e) => setQuoteForm({...quoteForm, customer_phone: e.target.value})}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label>Zipcode <span className="text-muted">Required</span></label>
                    <Input
                      type="text"
                      value={quoteForm.customer_zipcode}
                      name="customer_zipcode"
                      onChange={(e) => setQuoteForm({...quoteForm, customer_zipcode: e.target.value})}
                    />
                  </FormGroup> 
                  <FormGroup>
                    <label>Contact Preference</label>
                    <select
                      value={quoteForm.contact_preference}
                      onChange={(e) => setQuoteForm({...quoteForm, contact_preference: e.target.value})}
                      className="form-control"
                    >
                      <option value="Either">Either</option>
                      <option value="Email">Email</option>
                      <option value="Phone">Phone</option>
                    </select>
                  </FormGroup>
                </div>
              : null}
              {quoteForm.form_step === 1 && quoteForm.loading === true && quoteForm.customer_zipcode !== "" ?
                <Alert color="primary"><FontAwesomeIcon icon={faSpinner} spin className="mr-3" /> Finding dealers near you.</Alert>
              : null}
              {quoteForm.form_step === 1 && quoteForm.loading === true && quoteForm.customer_zipcode === "" ?
                <Alert color="primary"><FontAwesomeIcon icon={faSpinner} spin className="mr-3" /> Creating your pdf, this may take 20-30 seconds.</Alert>
              : null}
              {quoteForm.form_step === 2 && quoteForm.loading === true ?
                <div>
                <Alert color="success"><FontAwesomeIcon icon={faCheck} className="mr-3" /> Your quote has been submitted! Someone will be in touch soon if you entered your zipcode.</Alert>
                <Alert color="primary"><FontAwesomeIcon icon={faSpinner} spin className="mr-3" /> Creating your pdf, this may take 20-30 seconds.</Alert>
                </div>
              : null}
              {quoteForm.form_step === 2 && quoteForm.loading === false ?
                <div>
                <Alert color="success"><FontAwesomeIcon icon={faCheck} className="mr-3" /> Your quote has been submitted! Someone will be in touch soon if you entered your zipcode.</Alert>
                </div>
              : null}
            </form>}
            {quoteForm.form_step === 2 && quoteForm.loading === false && userId !== undefined ? 
                <Alert color="success"><FontAwesomeIcon icon={faCheck} className="mr-3" /> Your quote has been submitted! The form will reset shortly</Alert>
              : null}
            {quoteForm.form_step === 4 && quoteForm.loading === false && userId !== undefined ? 
              <Alert color="success"><FontAwesomeIcon icon={faCheck} className="mr-3" /> A new quote from this lead has been created.</Alert>
            : null}
            {quoteForm.form_step === 3 && quoteForm.loading === false ? 
                <div>
                  <h5 className="pb-4 border-bottom border-warning"><strong><em>Once you select a dealer, they will contact you within 2 days to help find the perfect boat with your configuration.</em></strong></h5>
                  {quoteForm.dealerships.map((d) => {
                    return (
                      <div className="my-4" key={d.id}>
                        <h5 className="mb-0">{d.name} {d.distance !== undefined ? <span className="small">{d.distance.toFixed(2)}mi</span> : null}</h5>
                        <p className="mb-0">{d.website !== undefined ? d.website : null}</p>
                        <p className="mb-0">{d.address !== undefined ? d.address : null}, {d.city !== undefined ? d.city : null}, {d.state !== undefined ? d.state : null}</p>
                        <Button color="primary" onClick={() => submitQuote(d.dealership_id)}>Select</Button>
                      </div>
                    )
                  })}
                </div>
              : null}
          </ModalBody>
          <ModalFooter style={{minHeight: '71px'}}>
            {quoteForm.form_step === 3 && quoteForm.loading === false && userId === undefined ? <Button block color="dark" onClick={() => setQuoteForm({...quoteForm, form_step: 1})}>Back</Button> : null}
            {quoteForm.form_step === 1 && quoteForm.loading === false && userId === undefined ? <Button block color="primary" onClick={() => findClosestDealerships()} disabled={quoteForm.customer_zipcode === "" || quoteForm.customer_name === "" || quoteForm.customer_email === ""}>Find Dealers</Button> : null}
          </ModalFooter>
        </Modal>
      </div>
  );
}

export default Review;