import React from 'react';
import { 
    Row,
    Col,
    Card,
    CardImg,
    CardBody
} from 'reactstrap';
import './BoatGallery.sass';
import _ from 'lodash';

const BoatGallery = (props) => {
  // Get overlay images
  const boat_overlay_images = _.filter(props.order.options, {'overlay_img': 'Boat'});
  const bench_overlay_images = _.filter(props.order.options, {'overlay_img': 'Bench'});
  console.log("Bench images: ", bench_overlay_images)
  console.log("Boat images: ", boat_overlay_images);
  const model = _.find(props.order.options, {'id': '1'});
  const selected_model = _.find(model.options, {'id': model.selected_option});
  if (model !== undefined && selected_model !== undefined && selected_model !== null) {
    const boat =  selected_model.img_path !== null && selected_model.img_path !== "" ? selected_model.img_path : null;
    const bench = selected_model.img_bench_path !== null && selected_model.img_bench_path !== "" ? selected_model.img_bench_path : null;
    return (
      <Row className="boat-gallery my-2">
        <Col sm="12" md="8" className="align-self-center" style={{paddingRight: '.5rem'}}>
          <Card className="shadow-sm h-100">
            <CardBody>
              {boat_overlay_images.map((boi, i) => {
                if (boi.selected_option !== null) {
                  const selected_option = _.find(boi.options, {'id': boi.selected_option});
                  return selected_option.img_path !== null && selected_option.img_path !== '' ? <CardImg className="img-fluid " src={process.env.REACT_APP_IMG_URL+'/'+selected_option.img_path} style={{zIndex: i+1}} key={selected_option.id} alt="Boat" /> : null;
                } else { return null; }
              })}
              {model !== undefined && model !== '' ? <CardImg className="img-fluid " src={process.env.REACT_APP_IMG_URL +'/'+ (boat !== null ? boat : model.img_path)} alt="Boat" /> : null}
            </CardBody>
          </Card>
        </Col>
        <Col sm="12" md="4" className="align-self-center" style={{paddingLeft: '.5rem'}}>
          <Card className="shadow-sm h-100 d-none d-sm-block">
            <CardBody>
              {bench_overlay_images.map((boi, i) => {
                if (boi.selected_option !== null) {
                  const selected_option = _.find(boi.options, {'id': boi.selected_option});
                  console.log("SELECTED BENCH IMG: ", selected_option)
                  return selected_option.img_path !== null && selected_option.img_path !== '' ? <CardImg className="img-fluid " src={process.env.REACT_APP_IMG_URL+'/'+selected_option.img_path} style={{zIndex: i+1}} key={selected_option.id} alt="Bench" /> : null;
                } else { return null; }
              })}
              {bench !== undefined && bench !== null && bench !== '' ? <CardImg className="img-fluid" src={process.env.REACT_APP_IMG_URL +'/'+ (bench !== null ? bench : model.img_path)} alt="Bench" /> : null}
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  } else { return null; }
}

export default BoatGallery;