import React from 'react';
import { 
  FormGroup,
  Label,
  Input 
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

const Dropdown = (props) => {
  // console.log(props)
  const { form } = useParams();

  const handleOnChange = (e) => {
    console.log(e.target.value)
    if (props.handleSelectOption !== undefined) props.handleSelectOption(props.input, e.target.value);
  }

  const formatPrice = (price) => {
    let priceStr = "";
    if (price !== "0.00" && price !== 0 && price !== "0") {
      const priceFormatted = form === "cs" ? (price/0.64).toFixed(0) : price;
      priceStr = ` - $${priceFormatted}`;
    }

    return priceStr;
  }

  if (props.input.limit_rule !== undefined && props.input.limit_rule !== null) {
    let limitIndex = _.findIndex(props.input.options, function(o) {
      return o.id === props.input.limit_rule.y_value;
    });

    if (props.input.limit_rule.type === "1") {
      props.input.formatted_options = props.input.options.slice(limitIndex, props.input.options.length);
    }
    
    if (props.input.limit_rule.type === "2") {
      props.input.formatted_options = props.input.options.slice(0, limitIndex+1);
    }
  } else {
    props.input.formatted_options = props.input.options;
  }

  // console.log("DROPDOWN: ", props)
  return (
    <FormGroup className={props.input.required === "1" && props.input.selected_option === null && props.show_required ? "border border-danger rounded p-2" : ""}>
      <Label for={props.input.name} className={props.input.required === "1" ? "text-primary font-weight-bold" : "text-dark"}>{props.input.name}{props.toggleTooltip !== null ? <FontAwesomeIcon className="order-form-tooltip" icon={faInfoCircle} onClick={props.toggleTooltip} /> : null}</Label>
      <Input disabled={props.input.locked === "1" || props.disabled} type="select" name={props.input.name} value={(props.input.selected_option !== null && props.input.selected_option !== undefined) ? props.input.selected_option : ""} onChange={(e) => handleOnChange(e)}>
        <option value="">Select {props.input.name}</option>
        { props.input.formatted_options.map((o) => {
          return o.active === "1" ? <option value={o.id} key={o.id}>{o.avalon_name}{formatPrice(o.price)}</option> : null;
        })
        }
      </Input>
    </FormGroup>
  );
}

export default Dropdown;