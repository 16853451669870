import React from 'react';
import { 
  Row,
  FormGroup,
  Label,
  Input,
  Col
} from 'reactstrap';
import Price from '../Price';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const GridCheckbox = (props) => {
  if (props.default !== undefined) console.log("OPTION: ", props);
  return (
    <FormGroup className="order-form-checkbox grid">
      <Col className="text-dark">
        {props.img_url !== null ? <img src={props.img_url} alt={props.name} className="image-checkbox-img" onClick={() => props.onChange(props.id)} /> : null}
        <FormGroup check>
          <Label check>
            <Input disabled={props.disabled} type="checkbox" checked={props.selected} onChange={() => props.onChange(props.id)} />{' '}
            <span className={props.selected ? "text-dark" : "text-secondary"}>{props.name}</span><Price price={props.price} />
          </Label>
        </FormGroup>
      </Col>
    </FormGroup>
  )
}

const DropdownGrid = (props) => {
  if (props.input.options === undefined) return null;

  const handleOnChange = (option) => {
    if (props.handleSelectOption !== undefined) props.input.selected_option === option ? props.handleSelectOption(props.input, null) : props.handleSelectOption(props.input, option);
  }
  
  return (
    <FormGroup className={props.input.required === "1" && props.input.selected_option === null && props.show_required ? "border border-danger rounded p-2" : ""}>
      <Label for="primaryWallColor" className={props.input.required === "1" ? "text-primary font-weight-bold" : "text-dark"}>{props.input.name}{props.toggleTooltip !== null ? <FontAwesomeIcon className="order-form-tooltip" icon={faInfoCircle} onClick={props.toggleTooltip} /> : null}</Label>
      <Row noGutters>
        {props.input.options.map(option => {
          if (option === undefined) return null;
          if (option.active === "0") return null;
          return <GridCheckbox disabled={props.disabled} img_url={option.img_thumb_path !== null ? process.env.REACT_APP_IMG_URL+'/'+option.img_thumb_path : null} onChange={(option) => { handleOnChange(option)}} id={option.id} key={option.id} name={option.avalon_name} price={option.price} selected={option.id === props.input.selected_option} />;
        })}
      </Row>
    </FormGroup>
  );
}

export default DropdownGrid;