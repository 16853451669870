import React from 'react';
import { useRouteMatch, useParams, useHistory } from 'react-router-dom';
import {
  Col,
  Row
} from 'reactstrap';
import _ from 'lodash';
import Option from './Components/Option';

const PrimarySelect = (props) => {
  const p = useParams();
  const { url } = useRouteMatch();
  let history = useHistory();

  const models = props.order.options !== [] ? _.find(props.order.options, {'id': '1'}) : null;
  models.options = _.orderBy(models.options, 'option_order');
  const floorplans = props.order.options !== [] ? _.find(props.order.options, {'id': '2'}) : null;
  let input = p.model === undefined ? models : floorplans;

  const handlePrimarySelect = (option_id, url) => {
    props.handleSelectOption(input, option_id);
    if (input.id === "1" && floorplans.selected_option !== null) props.handleSelectOption(floorplans, null);
    history.push(url);
  }

  return (
    <Row className="py-4">
      {input.options.map((o) => {
        if (o.active === "0") return null;
        let range = o.option_id === '1' ? {} : null;
        if (range !== null && o.min !== null && o.max !== null) {
          range.min = (p.form === "cs" ? (parseInt(o.min, 10)/0.64).toFixed(0) : o.min);
          range.max = (p.form === "cs" ? (parseInt(o.max, 10)/0.64).toFixed(0) : o.max);
        } else {
          range = null;
        }
        const img = o.img_path !== null && o.img_path !== "" ? {src: `${process.env.REACT_APP_IMG_URL}/${o.img_path}`, alt: o.avalon_name} : null;
        const next_url = p.model === undefined ? `${url}/${o.id}` : `${url}/f/${o.id}/s/1`;
        return (
          <Col sm="12" md="6" lg="3" key={o.id} className="mb-4">
            <Option 
              history={history}
              price={p.model !== undefined ? (p.form === "cs" ? (o.price/0.64).toFixed(0) : o.price) : null}
              range={range}
              handleSelectOption={() => handlePrimarySelect(o.id, next_url)}
              value={o.id}
              title={(p.brand === "tahoe" && input.branded === "1") ? o.tahoe_name : o.avalon_name}
              img={img} />
          </Col>
        );
      })}
    </Row>
  );
}

export default PrimarySelect;